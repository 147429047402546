.tellAboutUsSection {
  padding-bottom: 10px;
  text-align: center;
  padding: 10px;
  padding-left: 30px;
  font-size: 15px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  max-width: var(--max_width_fullscreen);
  margin: 0 auto;
}

.cursor{
  cursor:pointer;
}

/* .tellAboutUsSection:hover {
  background-color: var(--hoverColor);
} */

.invisible-transition{
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.transition{
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
  /* height: 100px;
  transition: 0.5s ease-in-out;   */
  text-align: right;
  padding: 10px;
  padding-left: 30px;
  font-size: 15px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  max-width: var(--max_width_fullscreen);
  margin: 0 auto;
  /* transition: max-height 1s ease-in-out; */
}

.tellAboutUsSectionInScroll {
  flex: 0 0 auto;
  scroll-snap-align: start;
  /* width: 30vw; */
  width: 73%;

  background: var(--backgroud-color);
  border-radius: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;
  text-align: center;
  padding: 10px;
  /* padding-left: 30px; */
  border-left: 2px solid var(--backgroud-color);
  font-size: 15px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  max-width: var(--max_width_fullscreen);
  margin: 10px;
}

.traingleRight {
  flex: 0 0 auto;
  scroll-snap-align: start;
  /* width: 30vw; */
  width: 0;
	height: 0;
	/* border-top: 25px solid transparent; */
	border-left: 50px solid  var(--backgroud-color);
	border-bottom: 25px solid transparent;
  box-sizing: border-box;
  margin-top: 10px;
  margin-left: -20px;

}


.tellAboutUsContainer {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; /* Enable snapping on the x-axis */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  justify-content: start;
  align-items: stretch;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

/* Hide scrollbar for Chrome, Safari and Opera */
.tellAboutUsContainer::-webkit-scrollbar {
  display: none;
}

.right{
  text-align: right;
}
