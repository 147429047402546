.double-match-form {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  font-family: "Heebo-VariableFon";
}

.doublematch-body {
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  max-width: 400px;
  margin: 0 auto;
  border-radius: 3px;
  /* border: 1px solid var(--border-color); */
  /* height: 100%; */
  padding: 8px;
  margin-bottom: 60px;
  direction: rtl;
}

#btnMatch {
  background-color: var(--btnPressed-color);
  font-size: 14px;
  text-align: center;
  /* width: 90%; */
}

/* #btnMatch:hover {
  opacity: 100%;
} */

#btnFreeText1,
#btnFreeText2,
#btnImage1,
#btnImage2,
#facebook1,
#facebook2,
#instegram1,
#instegram2,
#linkedin1,
#linkedin2 {
  font-size: 12px;
  text-align: center;
  width: 100%;
  height: 100px;
  direction: rtl;
  white-space: pre-line;
}

#facebook1,
#facebook2,
#instegram1,
#instegram2,
#linkedin1,
#linkedin2 {
  height: 40px;
}
.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

/* #image1,
#image2, */
#facebook1,
#facebook2,
#prevMatchesArea,
#record1,
#record2,
#instegram1,
#instegram2,
#linkedin1,
#linkedin2 {
  display: none;
  direction: rtl;
  white-space: pre-line;
}

#match1,
#match2{
  /* height:0px; */
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);

  direction: rtl;
  white-space: pre-line;
  /* position: fixed;   */
  overflow-x: hidden;
}

.messegeMatch {
  direction: rtl;
  white-space: pre-line;
  /* font-size: 12px; */
  font-size: 0.83em;
  margin: 1px 5px;
  max-width: inherit;
}
/* 
form .radio-toolbar-choice,
form .radio-toolbar-choice2 {
  opacity: 0;
  position: fixed;
  margin: auto;
  display: flex;
  justify-content: center;
} */

.choiceCube {
  display: inline-block;
  background-color: var(--btn-color);
  margin: 2px;
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  border-radius: 2px;
  width: 31%;
  height: 48px;
  white-space: normal;
  text-align: center;
  border: 1px solid var(--border-color);
  position: relative;
  color: var(--btnText-color);
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.image-doubleMatch {
  height: 200px;
  border-radius: 2px solid black;
  border-radius: 100px;
  margin: 3px;
  max-width: 40%;
  object-fit: cover;
  box-shadow: 0px 0px 2px 2 px black;
  z-index: 0;
}

.cellularline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  box-sizing: border-box;
  /* padding-left: 30px; */
}

.cellularblock{
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* padding-top: 0px; */
  box-sizing: border-box;
  width: var(--wide-btn-width);
  margin: 8px auto;
  border: 1.5px solid var(--border-color);
  border-radius: 2px;
  padding: 0px 5px;
  font-size: 14px;
}

/* .choiceCube:focus,
.choiceCube:checked {
  background-color: var(--btnPressed-color);
  color: var(--btnPressedeText-color);
} */

.active {
  background-color: var(--btnPressed-color);
  color: var(--btnPressedeText-color);
}

.greyChoiceCube {
  background-color: var(--grey-color);
}

h5 {
  margin: 8px 0 2px 0;
}

.image-fullscreen {
  width: 100%;
  max-width: 100%;
  top: 100px;
  max-height: 100%;
  position: absolute;
  right: 3px;
  z-index: 1;
  object-fit: contain;
}

.iconsline {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 0px; */
  box-sizing: border-box;
  width: var(--wide-btn-width);
  margin: 5px auto;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 0px 5px;
  font-size: 14px;
}

.msgGet {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 0px; */
  box-sizing: border-box;
  width: var(--wide-btn-width);
  margin: 5px auto;
  border: 1px solid var(--btn-color);
  border-radius: 4px;
  padding: 0px 5px;
}

.parent-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* width: 50%; */
  margin: 5px auto;
}

.record-line {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 0px; */
  box-sizing: border-box;
  margin: 8px;
  direction: rtl;
  font-size: 0.83em;
}

.msgMatch {
  /* margin: 5px; */
  width: 94%;
  height: 38px;
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: center;
  resize: none;
  /* word-break: break-all; */
  direction: rtl;
  white-space: pre-line;
  border-radius: 4px;
  border-color: beige;
}

.lbl_border {
  direction: rtl;
  white-space: pre-line;
  font-size: 14px;
  /* float: right; */
  /* margin-top: 4px;
  margin-right: 3px;
  margin-left: 6px; */
  margin: 2px;
  padding: 2px;
  border: 1px solid var(--btn-color);
}

.vipDescription{
  background-color: var(--page-title-background-color);
  text-align: center;
  width: 80%;
  margin: 6px auto;
  opacity: 0.8;
  border-radius: 1px;
  direction: rtl;
}

.animationDown{
  animation: animateUpDown 3s infinite;
}


@keyframes animateUpDown {
  0% {
      opacity: 1;
      transform: rotate(0deg) translate(0px, -52px);
  }
  50% {
      opacity: 1;
      transform: rotate(0deg) translate(0px, 52px);
  }
  100% {
      opacity: 1;
      transform: rotate(0deg) translate(0px, -52px);
  }
}



