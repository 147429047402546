.full-screen-homepage {
  overflow: scroll;
  background-color: var(--backgroud-color);
}

.homepage-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Heebo-VariableFon";
  text-align: center;
  direction: rtl;
}

.h-nomargin {
  margin: 4px;
  color: var(--title-color);
  font-weight: normal;
}

.h-nomargin-bold {
  margin: 4px;
  color: var(--title-color);
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}

.h1-homepagewhite {
  margin: 4px;
  color: white;
  font-weight: bold;
  /* position: absolute;
  top: 15%; */
  /* transform: translate(-50%, -50%); */
  /* text-align: center; */
}

.subtitle {
  margin: 4px;
  color: white;
  font-weight: normal;
  position: absolute;
  top: 25%;
}

.signup-homepage {
  background-color: var(--backgroud-color);
  width: 200px;
  height: 37px;
  margin: auto;
  margin-bottom: 4px;
  margin-top: 6px;
  border-width: 0;
  text-align: center;
  font-size: 16px;

  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  border-radius: 11px;
  border: 2.5px solid var(--btn-color);
  line-height: 37px;
  cursor: pointer;
  text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 2px #e60073, 0 0 2px #e60073, 0 0 3px #e60073;
  /* -webkit-animation-name: flash_border;
  -webkit-animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  */
}

/* @keyframes flash_border {
  0% {
    border-color: var(--btn-color);
  }
  50% {
    border-color: var(--btnText-color);
  }
  100% {
    border-color: var(--btn-color);
  }
} */


.signup-homepage:hover {
  background-color: var(--hoverColor);
}

.videohomepage {
  max-height: 300px;
  width: 100vw;
  object-fit: cover;
}

.container_color1 {
  width: 100vw;
  background-color: var(--btn-home-color);
  margin: 0 auto;
  text-align: right;
  /* padding: 12px; */
}

.container_color2 {
  width: 100vw;
  background-color: #ffd4e1;
  margin: 0 auto;
  text-align: right;
  /* padding: 12px; */
}

.howItWork {
  line-height: 37px;
  font-size: 15px;
  align-items: center;
  font-weight: bold;
  direction: rtl;
  background-color: var(--backgroud-color);
  border: solid 2px var(--btn-color);
  padding: 10px;
  height: unset;
  width: 300px;
  min-height: 37px;
  margin: 5px auto 0;
  font-family: "Heebo-VariableFon";
  border-radius: 3px;
  flex-direction: row;
  color: var(--btnText-color);
}

.rectangle {
  height: 14px;
  width: 11px;
  background-color: #555;
  margin: auto;
  margin-top: 5px;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #555;
  margin: auto;
}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 20px solid #555;
  border-bottom: 15px solid transparent;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
}

.hazon {
  white-space: pre-wrap;
  font-size: medium;
  margin: 10px;
  font-family: "Heebo-VariableFon";
  padding: 4px;
  text-align: center;
}

.youtube-homepage{
  height: 450px;
  max-width: 500px;
  margin: 0 auto;
  margin-top:10px;
}

.flach{
  background-color: #e60073;
  border-radius: 50px;
  width: 120px;
  margin:  8px auto;
  /* left:60px; */
  /* rotate: 20deg; */
  padding: 7px 12px;
  /* margin-right: 22px; */
  /* margin-top: -42px; */
}

.max_width_fullscreen{
  max-width: var(--max_width_fullscreen);
  margin: 0 auto;
}

.margintop18{
  margin-top: 18px;
}

@media screen and (max-width: 500px) {
  /* .flach{
    margin: 12px;
    font-size: 14px;
    z-index: 4;
  } */

}
