.form-body {
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  max-width: 400px;
  margin: 0 auto;
  border-radius: 3px;
  /* border: 1px solid var(--border-color); */
  /* height: 100%; */
  padding: 5px;
  margin-bottom: 20px;
}

.forum-main-form {
  display: flex;
  flex-direction: column;
  font-family: "Heebo-VariableFon";
}

.allMsg {
  height: 370px;
  /* display: flex; */
  overflow: scroll;
  direction: rtl;
}

.forumMsg {
  top: 2px;
  width: 85%;
  background-color: var(--backgroud-color);
  font-size: 12px;
  border-radius: 2px;
  opacity: var(--opacity-hover-degree);
  direction: rtl;
  white-space: pre-line;
}

.nickname {
  top: 2px;
  width: 34px;
  font-size: 8px;
}

.forum-line {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  box-sizing: border-box;
  margin: 3px;
  direction: rtl;
}

.forum-line-my-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  box-sizing: border-box;
  margin: 3px;
  direction: ltr;
}

.msg-date {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  box-sizing: border-box;
  margin: 3px;
  font-size: 10px;
  border: 1 solid black;
  border-radius: 2px;
}
/* form .gender-buttons-container {
  opacity: 0;
  position: fixed;
  margin: auto;
  display: flex;
  justify-content: center;
} */

.error-message {
  direction: rtl;
  color: red;
}

.newMsgArea {
  direction: rtl;
}

.newMsg {
  /* margin: 5px; */
  /* width: 244px; */
  width: 100%;
  height: 50px;
  font-size: 13px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: right;
  resize: none;
  /* word-break: break-all; */
  direction: rtl;
  white-space: pre-line;
  border-radius: 4px;
}

.sendicon:hover {
  background-color: var(--hoverColor);
}

.change-nickname-button {
  color: white;
  width: 250px;
  text-align: center;
  text-decoration: underline;
  margin: auto;
  padding-top: 7px;
  cursor: pointer;
  font-size: smaller;
}

.change-nickname-title {
  width: 240px;
  font-size: larger;
  font-weight: bold;
  color: black;
  margin: 0px auto 10px;
  text-align: center;
}

.change-nickname-body {
  width: 240px;
  font-size: medium;
  color: black;
  margin: 0px auto 10px;
  text-align: center;
}

.change-nickname-email-input {
  min-width: 80%;
  font-size: larger;
  text-align: center;
}
.change-nickname-container {
  text-align: center;
  direction: rtl;
}

.change-nickname-popup-button {
  text-decoration: underline;
  font-size: medium;
  cursor: pointer;
  padding: 12px;
}
