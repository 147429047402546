.likePopup{
  background-color: white;
  z-index: 10;
  min-width: 160px;
  padding: 5px;
  direction: rtl;
  position: absolute;
  top: 200px;
  margin: 5px auto;
  border: 1px solid black;
  text-align: center;
  display: block;
  margin-left: 17px;
  margin-right: 29px;
}

  