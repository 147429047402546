.h5middle{
    margin: 14px auto 0;
    text-align: center;
    font-size: 16px;
   }

  .button-text-center-no-hover{
    background-color: var(--btn-color);
    width: var(--wide-btn-width);
    height: 37px;
    margin: 14px auto 0;
    padding: 0;
    border-width: 0;
    text-align: center;
    font-size: 20px;
    font-family: "Heebo-VariableFon";
    color: var(--btnText-color);
    border-radius: 3px;
    line-height: 37px;
   }