#haredArea{
    direction: rtl;
    white-space: pre-line;
    height: 0;
    transition: max-height 0.5s;
    overflow-x: hidden;

  }

  .haredAreaVisible{
    visibility: visible;
    height: auto !important;
  }

  