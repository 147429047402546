.headerHomepage {
  background-color: var(--container-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  z-index: 10;
  position: absolute;
  width: 98vw;
  border-bottom: 1px solid var(--container-color);
  /* border-top: 3px solid gold; */
}


.headerHomepage div {
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 49px; */
  height: 47px;
  margin: 0px auto; 
}

.headerHomepage .hamburger {
  background-image: url("./../../images/hamburger.png");
  width: 60px;
  margin-top: -2px;
}
.header .phone {
  background-image: url("../../images/phone.png");
}
.header .info-double-date {
  background-image: url("../../images/info.png");
}
.header .help {
  background-image: url("../../images/help.png");
}
.header .dice {
  background-image: url("../../images/dice.png");
}

.header .info:hover,
.header .help:hover,
.header .info-double-date:hover,
.button-slidepanel:hover {
  background-color: var(--hoverColor);
}

.headerHomepage .button-header:hover {
  opacity: var(--opacity-hover-degree);
}

.headerHomepage .hp-logo {
  /* background-image: url("../../images/logo-double-date.png"); */
  background-image: url("../../images/homepage-logo-color.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 60px;
  width: 60px;
  margin-top: -10px;
}

.tipexample{
  background-image: url("../../images/tipexample.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 155px;
  width: 260px;
  border: 1px solid black;
}

.headerHomepage .button-header {
  /* background-color: var(--btn-color); */
  background-image: linear-gradient(to bottom right, var(--btn-color), var(--border-color));
  width: 90px;
  height: 37px;
  padding: 0;
  border-width: 0;
  text-align: center;
  font-size: 20px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  border-radius: 25px;
  line-height: 37px;
  cursor: pointer;
  direction: rtl;
}

.headerHomepage .space {
  width: 40px;
}

#slidepanel {
  height: 1400px;
  width: 0; 
  padding: 0px;
  background: #ffffff;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 4;
  border-right: 1px solid #cccccc;
  border-bottom: 3px solid #cccccc;
  overflow-x: hidden; 
  transition: 0.5s; 
  overflow: scroll;
}

.button-slidepanel {
  padding:4px;
  line-height: 50px;
  text-align: right;
  padding-right: 15px;
  border-bottom: 1px solid #cccccc;
  font-size: 20px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  cursor: pointer;
  direction: rtl;
}

.bottom-line{
  border-bottom: 1px solid #cccccc;
}

.title-up-banner{
  direction: rtl;
  padding-top: 64px; 
  font-size: 14px;
  text-align: center;
  /* padding-right: 140px; */
  /* font-weight: bold !important; */
  text-decoration: underline;
}


.starTag{
  background-image: url("../../images/startag.png");
  background-size: cover;
  width: 52px;
  height: 46px;
  /* display: inline-block; */
  cursor: pointer;
  font-size: 11px;
  /* float: left; */
}

.bell{
  background-image: url("../../images/bell.png");
  background-size: cover;
  width: 40px;
  height: 40px;
  /* display: inline-block; */
  cursor: pointer;
  font-size: 11px;
  /* float: left; */
}

.gap{
  width: 10px;
}

@media screen and (min-width: 400px) {
  .gap{
    width: 60px;
  }

}