.login-form1 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Heebo-VariableFon";
}

.login-form {
  background-color: var(--backgroud-color);

  /* border: 2px solid var(--border-color); */
  max-width: 400px;
  margin: 20px auto;
  height: 340px;
  width: 88%;
  border-radius: 7px;
  padding: 10px;
 
}

.error-message {
  direction: rtl;
  color: red;
  text-align: center;
}

.password-input-container {
  position: relative;
  text-align: center;
  margin-right: 5px;
  margin-left: -5px;
}

.password-input-span {
  position: relative;
  padding-top: 22px;
  padding-left: 7px;
}

.eye-image-in-span {
  top: 22px;
  left: 15px;
  width: 30px;
  position: absolute;
}

.reset-password-button {
  color: var(--btn-color);
  width: 250px;
  text-align: center;
  text-decoration: underline;
  margin: auto;
  padding-top: 14px;
  cursor: pointer;
  font-size: 14px;
}

.reset-password-title {
  width: 240px;
  font-size: larger;
  font-weight: bold;
  color: black;
  margin: 0px auto 10px;
  text-align: center;
  font-family: "Heebo-VariableFon";
}

.reset-password-body {
  width: 240px;
  font-size: medium;
  color: black;
  margin: 0px auto 10px;
  text-align: center;
  font-family: "Heebo-VariableFon";
}

.reset-password-email-input {
  min-width: 80%;
  font-size: larger;
  text-align: center;
  font-family: "Heebo-VariableFon";
}
.reset-password-container {
  text-align: center;
}

.reset-password-popup-button {
  text-decoration: underline;
  font-size: medium;
  cursor: pointer;
  padding: 12px;
  font-family: "Heebo-VariableFon";
}

.signup-btn {
  margin: 0px auto;
  /* width: 250px; */
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  color: var(--btn-color);
  text-align: center;
  direction: rtl;
  cursor: pointer;
}

.singup-line {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 0px; */
  box-sizing: border-box;
  margin: 8px;
  direction: rtl;
  font-size: 0.83em;
  max-width: 180px;
  margin: 18px auto;
  white-space: pre-wrap;
}
