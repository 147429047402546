.reccontainer {
    justify-content: center;
    align-items: center;
    width: var(--wide-btn-width);
    margin: auto;
    margin-bottom: 5px;
    display:inline;
    
  }

.birec{
    width: 30px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    margin: 0 auto;
    position: relative;
    border:1px black solid;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.lblbirec{
    rotate: 315deg;
    padding: 3px;
    font-size: 12px;
    margin: 0 auto;
    text-align: center; 
    max-width: 60px;  
}

.myline{
  border-bottom: 1px solid black;
}
.lblmiddle {
    direction: rtl;
    white-space: pre-line;
    font-size: 14px;
    /* float: right; */
    margin: 0 auto;
    text-align: center;
}

#agespie {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 30px auto;
  }

  .mypie{
    position: relative;
    width: 380px;
    height: 380px;
    margin: 30px auto;
    direction: ltr;
  }
  
  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }
  
  .animate {
    -webkit-transition: 0.2s cubic-bezier(.74,1.13,.83,1.2);
    -moz-transition: 0.2s cubic-bezier(.74,1.13,.83,1.2);
    -o-transition: 0.2s cubic-bezier(.74,1.13,.83,1.2);
    transition: 0.2s cubic-bezier(.74,1.13,.83,1.2);
  }
  
  .animate:hover {
    transform: scale(1.1);
    transform-origin: center center;
  }
  /* top, right, bottom, left */
  #part1 {
    background-color: #E64C65;
    -webkit-clip-path: polygon( 50% 0, 50% 50%, 100% 41.2%, 100% 0); 
    clip-path: polygon(50% 0, 50% 50%, 100% 41.2%, 100% 0);
  }
  
  #part2 {
    background-color: #11A8AB;
    /* -webkit-clip-path: polygon(50% 50%, 100% 41.2%, 100% 100%, 63.4% 100%); */
    clip-path: polygon(50% 50%, 100% 41.2%, 100% 100%, 63.4% 100%);
  }
  
  #part3 {
    background-color: #4FC4F6;
    -webkit-clip-path: polygon(50% 50%, 36.6% 100%, 63.4% 100%);
    clip-path: polygon(50% 50%, 36.6% 100%, 63.4% 100%);
  }
  
  #part4 {
    background-color: #FFED0D;
    -webkit-clip-path: polygon(50% 50%, 0 100%, 36.6% 100%);
    clip-path: polygon(50% 50%, 0 100%, 36.6% 100%);
  }
  
  #part5 {
    background-color: #F46FDA;
    -webkit-clip-path: polygon(50% 50%, 0 36.6%, 0 100%);
    clip-path: polygon(50% 50%, 0 36.6%, 0 100%);
  }
  
  #part6 {
    background-color: #15BFCC;
    -webkit-clip-path: polygon(50% 50%, 0 36.6%, 0 0, 50% 0);
    clip-path: polygon(50% 50%, 0 36.6%, 0 0, 50% 0);
  }
  