.SuperMatchMaker .whatsupp {
    /* background-image: url("./../../images/whatsuppicon.png"); */
    margin-bottom: 2px;
    width: 100px;
    height: 20px;
    background-color: aqua;
  }

  .supermatchmaker-form{
    direction: rtl;
  
  }

  .whitebackground{
    background-color: var(--container-color);
    width: var(--body-inside-container-width);
    max-width: 400px;
    margin: 0 auto;
    border-radius: 3px;
    padding: 5px;
    display: grid;
  }

  .areaAboutMeCard
  {
    visibility: visible;
    height: auto;
    padding-top: 8px;
    font-size: 12px;
    text-align: center;
    width: 100%;
    white-space: pre-line;
  }

  .invisible{
    visibility: hidden;
    height: 0px;
  }

  .button-small-letters{
    background-color: var(--btn-color);
    width: var(--wide-btn-width);
    height: 37px;
    margin: 14px auto 0;
    padding: 0;
    border-width: 0;
    text-align: center;
    font-size: 14px;
    font-family: "Heebo-VariableFon";
    color: var(--btnText-color);
    border-radius: 3px;
    line-height: 37px;
    cursor: pointer;
  }

  .userline{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    max-width: inherit;
  }

  .stopWhatsappBtn{
    cursor: pointer;
    font-size: 12px;
    align-items: center;
    direction: rtl;
    background-color: var(--btn-color);
    height: unset;
    margin: 14px auto 0;
    border-width: 0;
    border-radius: 3px;
    color: var(--btnPressedeText-color);
    width: 20%;
    padding: 8px;
    line-height: 14px;
    height:46px;
    font-weight: bold;
  }