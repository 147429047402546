.header {
  background-color: var(--container-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.headerback{
  background-color: var(--container-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  direction: rtl;
}
/* .header div {
  background-size: cover;
  background-repeat: no-repeat;
  width: 49px;
  height: 47px;
  margin: 0px auto;
} */

.header .running {
  width: 90px;
  font-size: small;
  border: 2px solid black;
  text-align: center;
  line-height: 22px;
  direction: rtl;
  font-family: "Heebo-VariableFon";
}
.header .hamburger {
  background-image: url("./../../images/hamburger.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 60px;
  height: 47px;
  margin: 0px auto;
  margin-top: -8px;
  cursor: pointer;
  float: right;
}
.header .phone {
  background-image: url("../../images/phone.png");
}
.header .info-double-date {
  background-image: url("../../images/info.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 49px;
  height: 47px;
  margin: 0px auto;
}
.header .help {
  background-image: url("../../images/help.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 49px;
  height: 47px;
  margin: 0px auto;
}
.header .dice {
  background-image: url("../../images/dice.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 40px;
  height: 38px;
  margin: 0px auto;
}

.header .vip {
  background-image: url("../../images/vip.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 70px;
  height: 38px;
  margin: 0px auto;
}

.vip {
  background-image: url("../../images/vip.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 46px;
  height: 23px;
  margin: 0px auto;
}

.backhome {
  background-image: url("../../images/backhome.png");
  background-size: cover;
  width: 26px;
  height: 26px;
  display: inline-block; 
  cursor: pointer;
  margin-right: 10px;
}

a, a:hover, a:focus, a:active {
  color: inherit;
}




.header .info:hover,
.header .help:hover,
.header .info-double-date:hover{
  background-color: var(--hoverColor);
}

#slidepanel1 {
  height: 1400px;
  width: 0; 
  padding: 0px;
  background: #ffffff;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 12;
  border-right: 1px solid #cccccc;
  border-bottom: 3px solid #cccccc;
  overflow-x: hidden; 
  transition: 0.5s;
  overflow: scroll;
}

.header .space {
  width: 80px;
}

.header .invisible{
  visibility: hidden;
}

.lbl_grey{
  direction: rtl;
  white-space: pre-line;
  font-size: 14px;
  /* float: right; */
  margin-top: 4px;
  margin-right: 3px;
  margin-left: 6px;
  color: rgb(81, 80, 80);
}

.bellNote{
  padding:4px;
  text-align: right;
  padding-right: 5px;
  border-bottom: 1px solid #cccccc;
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  cursor: pointer;
  direction: rtl;
}

.redCircle{
    width: 17px;
    height: 17px;
    background-color: red;
    color: white;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
}


