.signup-form {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  font-family: "Heebo-VariableFon";
}

#h1 {
  width: 240px;
  font-size: 24px;
  color: var(--title-color);
  margin: 0px auto 10px;
  text-align: center;
}

#h2 {
  margin: 0px auto 10px;
  width: 240px;
  font-size: 12px;

  color: var(--title-color);
  text-align: center;
}

.signup .signup-container {
  background-color: var(--backgroud-color);
  width: var(--container-width);
  margin: 0 auto;
  border-radius: 3px;
  height: 370px;
  display: flex;
  align-items: center;
}

.signup-body {
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  max-width: 400px;
  margin: 0 auto;
  border-radius: 3px;
  /* border: 1px solid var(--border-color); */
  /* height: 100%; */
  padding: 5px;
  margin-bottom: 20px;
  display: grid;
  max-width: inherit;

}

/*form .mich [type="div"]{
    opacity: 0;
    position: fixed;
    margin: auto;
    display: flex;
    align-items: center;
    color: #8c13aa;
  }
*/
.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

/* form .gender-buttons-container {
  opacity: 0;
  position: fixed;
  margin: auto;
  display: flex;
  justify-content: center;
} */

.gender-buttons-container {
  display: inline-block;
  /* background-color: var(--btn-color); */
  padding: 10px 0px 10px 0px;
  margin: 3px;
  font-size: 20px;
  /* width: 74px; */
  text-align: center;
  align-items: center;
  position: relative;
  color: var(--btnText-color);
  width: 100%;
}

.male,
.female {
  background-color: var(--btn-color);
  width: 45%;
  display: inline-block;
  /* padding: 10px; */
  margin: 2px;
  font-family: "Heebo-VariableFon";
  border-radius: 3px;
  text-align: center;
  border: 1px solid var(--border-color);
  /* text-align: center; */
  /* align-items: center;   */
  position: relative;
}

.male:hover,
.female:hover {
  opacity: var(--opacity-hover-degree);
}

.active {
  background-color: var(--btnPressed-color);
  color: var(--btnPressedeText-color);
}

.referToWeb {
  /* margin: 5px; */
  width: var(--body-inside-container-width);
  max-width: 400px;
  height: 25px;
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: center;
  resize: none;
  /* word-break: break-all; */
  direction: rtl;
  white-space: pre-line;
  border-radius: 4px;
}

/* form .button {
  background-color: var(--btn-color);
} */

form .error {
  font-size: 12px;
  margin: 0 auto;
  margin-top: 1px;
  margin-bottom: -13px;
  color: red;
}

.terms-and-conditions-contianer {
  direction: rtl;
  padding-top: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  width: 95%;
  margin-top: -4px;
  margin-bottom: 4px;
}

.terms-and-conditions-checkbox {
  width: 20%;
  min-width: 20px;
  /* padding: 0;
  margin: 0px; */
  font-size: large;
  height: 20px;
  float: right;
  padding: 5px;
}

.terms-and-conditions-button {
  color: rgb(88, 88, 87);
  cursor: default;
 
}

.error-message {
  direction: rtl;
  color: red;
}
