.terms-and-conditions-container {
  color: black;
  direction: rtl;
  /* background-color: rgb(88, 88, 87); */
  white-space: pre-wrap;
  width: 90%;
  text-align: right;
  margin: auto;
}

.terms-and-conditions-p {
  overflow-y: scroll;
  white-space: pre-wrap;
  direction: rtl;
  max-height: 60vh;
  /* max-height: 70%; */
  font-size: 14px;
  margin: 10px;
  font-family: "Heebo-VariableFon";
  padding: 4px;
}

.popup-content {
  width: 100% !important;
  font-size: small;
  direction: rtl;
}
