/* .Footer {
  background-color: var(--page-title-background-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: absolute;
  bottom: 0px;
  width: 100vw;
  background-image: linear-gradient(
    to bottom right,
    var(--btn-color),
    var(--btnPressed-color)
  );
} */

.Footer {
  /* background-color: var(--container-color); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  position: absolute;
  bottom: 0px;
  width: 100vw;
}

.Footer div,
.Footer a {
  background-size: cover;
  background-repeat: no-repeat;
  width: 40px;
  height: 38px;
   /* margin: 0px auto;  */
}

.footer-text {
  margin: 0px auto;
  /* width: 250px; */
  font-weight: bold;
  font-size: medium;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: center;
  direction: rtl;
}

.Footer .facebook {
  background-image: url("./../../images/facebookicon.png");
  margin: 3px;
  height: 90%;
  width: 90%;
}

.Footer .whatsupp {
  background-image: url("./../../images/whatsuppicon.png");
  margin: 0px 15px;
  margin-bottom: 4px;

}

.Footer .mettillnow,
.sign-up-free {
  /* background-image: url("./../../images/pinkcircle.png"); */
  cursor: pointer;
  border-radius: 90px;
  background-color:var(--container-color);
  border: 10px solid var(--btn-color);
  width:50px;
  height: 50px;
  line-height: 15px;
  font-weight: bold;
  font-size: 12px;
  margin: 0px 30px;
  margin-bottom: 45px;
  padding: 8px;
  padding-top: 11px;
  text-align: center;
  /* animation: skew 3s infinite;
  transform: skew(20deg);
  animation-direction: alternate; */
  direction: rtl;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;

}

.Footer .encourage {
  background-image: url("./../../images/encourageicon.png");
  cursor: pointer;
}

.Footer .encourage:hover,
/* .Footer .whatsupp:hover, */
.Footer .facebook:hover {
  background-color: var(--hoverColor);
}
/* 
@keyframes skew {
  0% {
    transform: skewX(20deg);
  }
  100% {
    transform: skewX(-20deg);
  }
} */
