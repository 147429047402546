.newQuestionBtn{
  width:160px;
  height: 60px;
  background-color:  var(--btn-color);
  margin: 0 auto;
  direction: rtl;
  border: 4px solid palevioletred;
  text-align: center;
  line-height: 60px;
  border-radius: 1px;
}

.newQuestionBtn:hover{
  opacity: var(--opacity-hover-degree);
}

.fundatephoto{
  background-size: cover;
  width: 240px;
  height: 130px;
  /* display: inline-block; */
  margin: 0 auto;
  border: 1px solid black;
  padding: 3px ;
  transition: all 0.6s ease-out;
}
.oldObject{ background-image: url("../../images/oldobject.png");}
.tap{  background-image: url("../../images/tap.png");}
.waytojerus{  background-image: url("../../images/waytojerus.png");}
.globe{background-image: url("../../images/globe.png");}
.changecareere{ background-image: url("../../images/changecareere.png");}
.moovie{background-image: url("../../images/moovie.png");}
.extreme{ background-image: url("../../images/extreme.png");}
.hundrednis{background-image: url("../../images/hundrednis.png");}
.food{ background-image: url("../../images/food.png");}
.teacher{background-image: url("../../images/teacher.png");}
.cars{background-image: url("../../images/cars.png");}
.cellphone{background-image: url("../../images/cellphone.png");}
.philantrop{background-image: url("../../images/philantrop.png");}
.lowkid{background-image: url("../../images/lowkid.png");}
.boss{background-image: url("../../images/boss.png");}
.castume{background-image: url("../../images/castume.png");}
.beggar{background-image: url("../../images/beggar.png");}
.moses{background-image: url("../../images/moses.png");}
.supermarket{background-image: url("../../images/supermarket.png");}

.textcontainer {
  position: relative;
  max-width: 340px;
}

.fundatetext{
  text-align: center;
  direction: rtl;
  height: 200px;
  /* position: absolute; */
    bottom: 0;
    vertical-align: bottom;
   display: table-cell;
}

.aquestion{
  border: 1px dashed black;
  padding: 8px;
  background-color:white;
}

.newQuestionBtngrey{
  width:240px;
  height: 60px;
  background-color:  grey;
  margin: 0 auto;
  direction: rtl;
  text-align: center;
  line-height: 60px;
  border-radius: 1px;
}

.smalllogo{
  background-size: cover;
  width: 42px;
  height: 42px;
  /* display: inline-block; */
  margin: 0 auto;
  padding: 3px ;
  background-image: url("../../images/smalllogo.png");
  float: left;
}

.lbl_left{
  line-height: 60px;
 font-size: 10px;
 
}