

.ShirliVal{
  background-image: url("../../images/ShirliVal.jpeg");
  background-size: cover;
  width: 62px;
  height: 62px;
  float: left;
  border-radius: 30px;
  margin-left: -3px;
}



.doublefriendarea{
  width: var(--wide-btn-width);
  text-align: right;
  color: var(--btnText-color);
  padding: 10px;
  font-size: 14px;
  direction: rtl;
}


.rightCol {
  flex-direction: column;
  width:70%;
  float:right
}

.leftCol {
  flex-direction: column;
  width:30%;
  float:left;
  max-width: 80px;
}

.whatsupTips {
  background-image: url("../../images/whatsuppicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
  margin-top: 7px;
}
