.form-body {
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  max-width: 400px;
  margin: 0 auto;
  border-radius: 3px;
  /* border: 1px solid var(--border-color); */
  /* height: 100%; */
  padding: 5px;
  margin-bottom: 60px;
}

.EgozaW {
  background-image: url("../../images/EgozaW.jpeg");
  background-size: cover;
  width: 62px;
  height: 62px;
  float: left;
  border-radius: 30px;
  margin-left: -3px;
}

.NadavK {
  background-image: url("../../images/NadavK.png");
  background-size: cover;
  width: 62px;
  height: 62px;
  float: left;
  border-radius: 30px;
  margin-left: -3px;
}

.MichalI {
  background-image: url("../../images/MichalI.png");
  background-size: cover;
  width: 130px;
  height: 120px;
  border-radius: 30px;
}

.RomiM {
  background-image: url("../../images/RomiM.png");
  background-size: cover;
  width: 65px;
  height: 60px;
  border-radius: 30px;
}

.MosheI {
  background-image: url("../../images/MosheI.png");
  background-size: cover;
  width: 65px;
  height: 60px;
  border-radius: 30px;
}

.TomerC {
  background-image: url("../../images/TomerC.png");
  background-size: cover;
  width: 65px;
  height: 60px;
  border-radius: 30px;
}

.ShirliVal{
  background-image: url("../../images/ShirliVal.jpeg");
  background-size: cover;
  width: 62px;
  height: 62px;
  float: left;
  border-radius: 30px;
  margin-left: -3px;
}

.EliGaron{
  background-image: url("../../images/EliGaron.jpeg");
  background-size: cover;
  width: 62px;
  height: 62px;
  float: left;
  border-radius: 30px;
  margin-left: -3px;
}

.OsifGonen{
  background-image: url("../../images/OsifGonen.jpeg");
  background-size: cover;
  width: 62px;
  height: 62px;
  float: left;
  border-radius: 30px;
  margin-left: -3px;
}

.SaritShavit{
  background-image: url("../../images/SaritShavit.jpeg");
  background-size: cover;
  width: 62px;
  height: 62px;
  float: left;
  border-radius: 30px;
  margin-left: -3px;
}

.Sariel{
  background-image: url("../../images/Sariel.jpeg");
  background-size: cover;
  width: 62px;
  height: 62px;
  float: left;
  border-radius: 30px;
  margin-left: -3px;
}

.coupon {
  background-image: url("../../images/couponicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
}


.tipArea{
  /* border: 1px solid var(--btnText-color);
  border-radius: 2px; */
  width: var(--wide-btn-width);
  text-align: right;
  /* margin: 14px auto 0; */
  color: var(--btnText-color);
  padding: 10px;
  font-size: 14px;
  direction: rtl;
  display: none;
  
}

.tip1{
  border-bottom: 1px solid var(--btnText-color);
  /* border-radius: 2px; */
  margin: 14px auto 0;
  padding: 3px;
}

.rightCol {
  flex-direction: column;
  width:70%;
  float:right
}

.leftCol {
  flex-direction: column;
  width:30%;
  float:left;
  max-width: 80px;
}

.whatsupTips {
  background-image: url("../../images/whatsuppicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
  margin-top: 7px;
}
