.containerPrevMatches
{
  color: var(--title-color);
  max-width: 350px;
  margin: 0 auto;
  background-color: var(--container-color);
  overflow: scroll;
  max-height: 600px;

}

.prevMatchName{
text-align: center;
font-size: 13px;
opacity: 0.7;
width: var(--wide-btn-width);
height: 22px;
margin: 4px auto 0;
border-radius: 2px;
line-height: 22px;
box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 4px 7px 0, rgba(0, 0, 0, .12) 0 1px 12px 0;
}

#prevMatchReportArea,
.prevMatchReportArea{
    display: none;
}

.prevMatchReportBtn,
.hatunaReportBtn,
.longTextReportBtn {
 display: inline-block;
background-color: var(--btn-color);
padding: 2px;
margin: 2px;
font-size: 9px;
font-weight: 600;
font-family: "Heebo-VariableFon";
border-radius: 2px;
width: 23%;
height: 46px;
white-space: normal;
text-align: center;
border: 1px solid var(--border-color);
position: relative;
color: var(--btnText-color);
letter-spacing: 1px;
line-height: 120%;
-webkit-appearance: none;
}

/* .hatunaReportBtn{
  font-size: 20px;
  top: 9px
} */

.longTextReportBtn{
  top: -6px
}

.prevMatchReportBtn:hover,
.hatunaReportBtn:hover,
.longTextReportBtn:hover,
.prev-matches-button:hover{
  opacity: var(--opacity-hover-degree);
}

@media screen and (min-width: 500px) {
  .longTextReportBtn{
    top: 5px
  }

  .hatunaReportBtn{
    top:-6px
  }
}

.btn-save-report{
  background-color: white;
  width: 100px;
  height: 25px;
  margin: 0 auto;
  padding: 0;
  border-width: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid black ;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  border-radius: 2px;
  line-height: 25px;
  cursor: pointer;
}

.prev-matches-button {
  /* color: var(--btn-color); */
  color: var(--btnText-color);
  text-decoration: underline;
  width: 250px;
  text-align: center;
  /* text-decoration: underline; */
  margin: auto;
  padding-top: 14px;
  cursor: pointer;
  font-size: 14px;

}
  

  