.almost-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Heebo-VariableFon";
  text-align: center;
  direction: rtl;
  background-color: var(--backgroud-color);
  margin: 5px auto;
  padding: 8px;
  max-width: 500px;
}

.almost-form .h-nomargin {
  margin: 14px;
  color: var(--title-color);
  font-weight: normal;
}

.almost-form .toggle-line{
  border-bottom: 1px solid #cccccc;
  padding-bottom: 12px;

}

.paramAlmost{ 
  margin-top: -20px;
}
