.aboutdouble-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Heebo-VariableFon";
  text-align: right;
  direction: rtl;
  background-color: var(--backgroud-color);
  margin: 5px auto;
  padding: 8px;
  max-width: 500px;
}

.h-nomargin {
  margin: 4px;
  color: var(--title-color);
  font-weight: normal;
}

.widthfix120{
  width:120px;
}

.h-nomargin-small {
  margin: 4px;
  color: var(--title-color);
  font-size: 14px;
}

.h1-homepage {
  margin: 4px;
  color: var(--title-color);
  font-weight: bold;
}

.aboutdouble-full-screen {
  overflow: scroll;
}
