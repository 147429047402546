.aboutdouble-form .encourage:hover,
.aboutdouble-form .whatsupp:hover,
.aboutdouble-form .facebook:hover,
.aboutdouble-form .gmail:hover,
#backBtnContact:hover {
  /* background-color: var(--hoverColor); */
  opacity: var(--opacity-hover-degree);
}

.aboutdouble-form #backBtn {
  margin-top: 25px;
  width: 180px;
}
