.aboutdouble-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Heebo-VariableFon";
  text-align: right;
  direction: rtl;
  background-color: var(--backgroud-color);
  margin: 5px auto;
  padding: 8px;
  max-width: 500px;
}

.h-nomargin {
  margin: 4px;
  color: var(--title-color);
  font-weight: normal;
}

.aboutdouble-full-screen {
  overflow: scroll;
}
.aboutdouble-form div,
.aboutdouble-form a,
.contactUsHelper div {
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px auto;
}

.contactUsHelper a {
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px 18px;
}

.contactUsHelper .facebook {
  background-image: url("./../../images/facebookicon.png");
  margin: 3px;
  width: 40px;
  height: 38px;
}

.contactUsHelper .whatsupp {
  background-image: url("./../../images/whatsuppicon.png");
  margin: 3px;
  width: 46px;
  height: 46px;
}

.contactUsHelper .gmail {
  background-image: url("./../../images/gmailicon.png");
  margin: 3px;
  width: 46px;
  height: 34px;
}

.aboutdouble-form .encourage:hover,
.contactUsHelper .whatsupp:hover,
.contactUsHelper .facebook:hover,
.contactUsHelper .gmail:hover,
#backBtnContact:hover {
  /* background-color: var(--hoverColor); */
  opacity: var(--opacity-hover-degree);
}

.aboutdouble-form #backBtn {
  margin-top: 25px;
  width: 180px;
}
