.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    direction: rtl;
    /* width: 240px;
    height: 30px;
    padding: 10px 20px; */
    /* font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px; */
    /* border-radius: 4px; */
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    direction: rtl;
    display: block;
    position: absolute;
    top: 51px;
    width: 100%;
    background-color: #eee;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__suggestion-match {
    color: red;
    font-weight: bold;
}