.form-body {
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  max-width: 400px;
  margin: 0 auto;
  border-radius: 3px;
  /* border: 1px solid var(--border-color); */
  /* height: 100%; */
  padding: 5px;
  margin-bottom: 60px;
}

.choose_room {
  display: flex;
  flex-direction: column;
  font-family: "Heebo-VariableFon";
  align-items: center;
  margin: 12px;
}

.nickname {
  top: 2px;
  width: 34px;
  font-size: 8px;
}

.forum-line {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  box-sizing: border-box;
  margin: 3px;
  direction: rtl;
}

.change-nickname-button {
  color: white;
  width: 250px;
  text-align: center;
  text-decoration: underline;
  margin: auto;
  padding-top: 7px;
  cursor: pointer;
  font-size: smaller;
}

.change-nickname-title {
  width: 240px;
  font-size: larger;
  font-weight: bold;
  color: black;
  margin: 0px auto 10px;
  text-align: center;
}

.change-nickname-body {
  width: 240px;
  font-size: medium;
  color: black;
  margin: 0px auto 10px;
  text-align: center;
}

.change-nickname-email-input {
  min-width: 80%;
  font-size: larger;
  text-align: center;
}
.change-nickname-container {
  text-align: center;
  direction: rtl;
}

.change-nickname-popup-button {
  text-decoration: underline;
  font-size: medium;
  cursor: pointer;
  padding: 12px;
}
.choose-room-btn {
  cursor: pointer;
  line-height: 37px;
  min-width: 100px;
  font-size: 18px;
  align-items: center;
  font-weight: bold;
  direction: rtl;
  background-color: var(--btn-home-color);
  padding: 10px;
  height: unset;
  width: var(--wide-btn-width);
  height: 37px;
  margin: 14px auto 0;
  border-width: 0;
  font-family: "Heebo-VariableFon";
  border-radius: 3px;
}

.choose-room-btn-long-text {
  cursor: pointer;
  line-height: 37px;
  min-width: 100px;
  font-size: 14px;
  align-items: center;
  font-weight: bold;
  direction: rtl;
  background-color: var(--btn-home-color);
  padding: 10px;
  height: unset;
  width: var(--wide-btn-width);
  height: 37px;
  margin: 14px auto 0;
  border-width: 0;
  font-family: "Heebo-VariableFon";
  border-radius: 3px;
}

.choose-room-btn-grey {
  cursor: pointer;
  line-height: 37px;
  min-width: 100px;
  font-size: 18px;
  align-items: center;
  font-weight: bold;
  direction: rtl;
  background-color: var(--grey-color);
  opacity: 0.45;
  padding: 10px;
  height: unset;
  width: var(--wide-btn-width);
  height: 37px;
  margin: 14px auto 0;
  border-width: 0;
  font-family: "Heebo-VariableFon";
  border-radius: 3px;
}

.choose-room-btn:hover {
  opacity: var(--opacity-hover-degree);
}
